import React, { useRef, useState } from 'react';
import './MatchDetails.css';
import { useParams } from 'react-router-dom';

import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { MediaPlayer, MediaPlayerInstance, MediaProvider } from '@vidstack/react';
import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';
import MatchEventTypeListElement, { MatchEventType } from '../components/MatchEventType';
import { useSearchParams } from "react-router-dom";
import { Helmet } from 'react-helmet-async';


function MatchDetails() {
  let { id } = useParams();
  const [match, setMatch] = useState<any>(null);
  const [isAdmin, setAdmin] = useState<boolean>(false);
  const [matchEvents, setMatchEvents] = useState<Array<any>>([]);
  const playerRef = useRef<MediaPlayerInstance>(null);
  const [searchParams] = useSearchParams();


  if(match == null) {
    fetch(`/api/match/${id}`).then((response) => response.json()).then(setMatch);
    fetch(`/api/match/${id}/event`).then((response) => response.json()).then(setMatchEvents);
  }

  function seekTo(event: MatchEventType) {
    if(playerRef.current) {
      playerRef.current!.currentTime = event.time-10;
      playerRef.current!.play();
    }
  }

  async function deleteEvent(event: MatchEventType) {
    await fetch(`/api/match/${id}/event/${event.id}`, { method: "DELETE"});
    setMatchEvents(matchEvents.filter((currentevent)=> currentevent.id!==event.id))
  }

  let t: number | undefined;
  if(searchParams.get("t") != null) {
    try {
      t = parseInt(searchParams.get("t")!)-10;
    } catch (error) {
      
    }
  }

  if(searchParams.get("admin") != null && !isAdmin) {
    setAdmin(true)
  }

  return (
    <>
    {match &&
      <Helmet>
          <title>{match?.name}</title>
          <meta name="og:description" content={match?.createdAt} />
          <meta property="og:title" content={match?.name} />
          <meta property="og:url" content={`https://starium.tv/match/${id}`} />
        </Helmet>
    }
      <div className="MatchDetails">
      
        <div className='center'>
          <MediaPlayer playsInline={true} muted={t!==undefined} autoPlay={t!==undefined} currentTime={t} ref={playerRef} src={`https://cdn.starium.tv/${id}.mp4`}>
            <MediaProvider />
            <DefaultVideoLayout icons={defaultLayoutIcons} />
          </MediaPlayer>
          <h1>{match?.name}</h1>
        </div>
        <ul className='event-list'>
          {matchEvents.map(matchEvent =>
            <MatchEventTypeListElement isAdmin={isAdmin} url={`https://starium.tv/match/${id}`} title={match?.name} key={matchEvent.id} match={match} event={matchEvent} onDelete={deleteEvent} onClick={seekTo} />
          )}
        </ul>
      </div>
    </>
  );
}

export default MatchDetails;
