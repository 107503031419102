import React, { useState } from 'react';
import './MatchDetails.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

export default function Matches() {
  const [matches, setMatches] = useState<any[] | null>(null);

  if(matches == null) {
    fetch(`/api/match/`).then((response) => response.json()).then((matches) => matches.reverse()).then(setMatches);
  }

  async function deleteMatch(match: any) {
    const response = await fetch(`/api/match/${match.id}`, { method: 'DELETE' });
    if(response.status===200 && matches !== null) {
      setMatches(matches.filter(a =>a.id !== match.id))
    }
  }

  return (
    <>
     <Helmet>
        <title>Matches</title>
      </Helmet>

    {matches &&
        <ul className="matches">
        {matches.map((match, index) => (
            <li key={match.id}> 
                <Link to={{ pathname: `/match/${match.id}`}}>{match.name}</Link>
            </li>
        ))}
        </ul>
    }
    </>
  );
}

